var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mvg-connections-outer-wrapper" }, [
    _vm.showTitle
      ? _c("div", { staticClass: "mvg-connections-header" }, [
          _vm._v(_vm._s(_vm.connectionName)),
        ])
      : _vm._e(),
    _vm.connections
      ? _c(
          "div",
          { staticClass: "mvg-wrapper" },
          _vm._l(_vm.connections, function (connection) {
            return _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.mvgTooltipConnection(connection),
                    expression: "mvgTooltipConnection(connection)",
                  },
                ],
                key: connection.uniqueId,
                staticClass: "line",
              },
              [
                _c("div", { staticClass: "departure" }, [
                  _c("span", { staticClass: "time" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("formatTime")(
                            connection.parts[0].from.plannedDeparture
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "delay",
                      class: {
                        "has-delay":
                          connection.parts[0].from.departureDelayInMinutes > 0,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          Math.max(
                            parseInt(
                              connection.parts[0].from.departureDelayInMinutes
                            ) || 0,
                            0
                          )
                        )
                      ),
                    ]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "changes" },
                  [
                    _vm._l(connection.parts, function (part, index) {
                      return [
                        index > 0
                          ? _c(
                              "span",
                              {
                                directives: [
                                  {
                                    name: "tooltip",
                                    rawName: "v-tooltip",
                                    value: part.from.name,
                                    expression: "part.from.name",
                                  },
                                ],
                                key: "change-" + index,
                                staticClass: "change",
                              },
                              [_vm._v("⬌")]
                            )
                          : _vm._e(),
                        part.line.transportType != "PEDESTRIAN"
                          ? _c(
                              "span",
                              {
                                key: "transport-" + index,
                                staticClass: "transport",
                                class: [
                                  "type-" + part.line.transportType,
                                  "line-" + part.line.label,
                                ],
                              },
                              [_vm._v(_vm._s(part.line.label))]
                            )
                          : _c("span", { key: "transport-" + index }, [
                              _vm._v("🚶"),
                            ]),
                      ]
                    }),
                  ],
                  2
                ),
                _c("span", { staticClass: "time" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("formatDuration")(
                          Date.parse(
                            connection.parts[connection.parts.length - 1].to
                              .plannedDeparture
                          ) -
                            Date.parse(
                              connection.parts[0].from.plannedDeparture
                            )
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }